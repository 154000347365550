import * as React from "react";
import { useRocksContract } from "./useContract";
import { BigNumber, utils } from "ethers";
import { rpcUrl } from "../constants";
import { useWeb3React } from "./useWeb3React";
import { JsonRpcProvider } from "@ethersproject/providers";
import { parseEther } from "ethers/lib/utils";

export interface IRockState {
  price?: number;
  available?: number;
  total?: number;
  maxBuy?: number;
  startTime?: number;
  tokensOf: { [owner: string]: number[] };
  lastError?: string;
  bal: BigNumber;
}

export function useRockState(fetch = false) {
  const [state, setState] = React.useReducer<
    (s: IRockState, n: Partial<IRockState>) => IRockState
  >((s, n) => ({ ...s, ...n }), { tokensOf: {}, bal: BigNumber.from(0) });
  const { library, account } = useWeb3React();
  const fallbackProvider = React.useMemo(() => new JsonRpcProvider(rpcUrl), []);

  const signer = React.useMemo(() => library?.getSigner(), [library]);

  const rocksContract = useRocksContract(signer ?? fallbackProvider);

  React.useEffect(() => {
    if (library && account) {
      library.getBalance(account).then((bal) => {
        setState({
          bal,
        });
      });
    }
  }, [account, library]);

  const fetchRocksOf = React.useCallback(
    async (owner: string) => {
      const bal: BigNumber = await rocksContract.balanceOf(owner);
      const ownerRockIds: number[] = [];
      if (bal.gt(0)) {
        const fetchingRocks: Promise<BigNumber>[] = [];

        for (let i = BigNumber.from(0); i.lt(bal); i = i.add(1)) {
          fetchingRocks.push(rocksContract.tokenOfOwnerByIndex(owner, i));
        }

        await Promise.all(fetchingRocks).then((rockIds: BigNumber[]) => {
          ownerRockIds.push(...rockIds.map((rid) => rid.toNumber()));
        });
      }

      setState({
        tokensOf: {
          ...state.tokensOf,
          [owner]: ownerRockIds,
        },
      });

      return ownerRockIds;
    },
    [rocksContract, state]
  );

  const fetchData = React.useCallback(async () => {
    const promises: Promise<any>[] = [];

    promises.push(
      rocksContract.maxSupply().then((maxSupply: BigNumber) => {
        setState({
          total: maxSupply.toNumber(),
        });
        return rocksContract.totalSupply().then((totalSupply: BigNumber) => {
          setState({
            available: maxSupply.toNumber() - totalSupply.toNumber(),
          });
        });
      })
    );

    promises.push(
      rocksContract.price().then((price: BigNumber) => {
        setState({
          price: Number(utils.formatEther(price)),
        });
      })
    );

    promises.push(
      rocksContract.maxBuy().then((maxBuy: BigNumber) => {
        setState({
          maxBuy: maxBuy.toNumber(),
        });
      })
    );

    promises.push(
      rocksContract.startTime().then((startTime: BigNumber) => {
        setState({
          startTime: startTime.toNumber(),
        });
      })
    );

    await Promise.all(promises).catch(console.error);
  }, [rocksContract]);

  React.useEffect(() => {
    let interval = -1;

    if (fetch) {
      fetchData();
      let fetching = false;
      interval = window.setInterval(async () => {
        if (!fetching) {
          fetching = true;
          await fetchData();
          fetching = false;
        }
      }, 2000);
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [fetchData, fetch]);

  const buyRocks = React.useCallback(
    async (num: number) => {
      try {
        const tx = await rocksContract.buyRocks(num, {
          value: parseEther((num * (state.price ?? 0)).toString()),
        });
        console.log(tx);
        const receipt = await tx.wait();
        return receipt.status;
      } catch (e) {
        console.error(e);
        const lastErrorMessage = (e.data?.message as string)?.replace(
          "execution reverted: ",
          ""
        );
        const code = e.data?.code;
        if (
          lastErrorMessage?.includes(
            "insufficient funds for gas * price + value"
          )
        ) {
          setState({
            lastError: `${e.message} - insufficient funds`,
          });
        } else {
          setState({
            lastError: `${e.message}${
              lastErrorMessage && code === 3 ? ` - ${lastErrorMessage}` : ""
            }`,
          });
        }
        return false;
      }
    },
    [rocksContract, state.price]
  );

  return {
    state,
    fetchRocksOf,
    buyRocks,
  };
}
