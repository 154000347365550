import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import discordLogoWhite from "../Content/Discord-Logo-White.svg";
import twitterLogoWhite from "../Content/twt-logo.svg";

const Container = styled.div`
  max-width: ${pr(960)};
  padding: ${pr(120)} 0 ${pr(80)};
  margin: auto;

  @media (max-width: 959px) {
    max-width: unset;
    padding: ${pr(120)} ${pr(24)} ${pr(40)};

    font-size: ${pr(12)};
  }

  div:not(.links) {
    padding-right: 25%;

    @media (max-width: 959px) {
      padding-right: 0;
    }

    p.copyright {
      margin-top: ${pr(30)};
    }
  }

  div.links {
    display: flex;
    justify-content: center;
    padding-bottom: ${pr(16)};

    a {
      margin: 0 ${pr(16)};

      img {
        width: ${pr(48)};
      }
    }
  }
`;

export const Footer: React.FC = () => {
  return (
    <Container>
      <div className="links">
        <a
          href="https://discord.gg/fgNVm9NNAw"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordLogoWhite} alt="Discord" />
        </a>
        <a
          href="https://twitter.com/AVAX_ROCKS"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitterLogoWhite} alt="Twitter" />
        </a>
      </div>
      <div>
        <p>
          This website and any materials related to this website are not
          affiliated with EtherRock, or any other organzations outside of this
          website.
        </p>
        <p className="copyright">© AvaxRock {new Date().getFullYear()}</p>
      </div>
    </Container>
  );
};
