import "styled-components";

interface ITheme {
  palette: {
    background: string;
    red: {
      main: string;
      dark: string;
    };
  };
}

declare module "styled-components" {
  export interface DefaultTheme extends ITheme {}
}

export const DefaultTheme: ITheme = {
  palette: {
    background: "#120D23",
    red: { main: "#E93B49", dark: "#AD2D37" },
  },
};

export const pr = (value: number) => `${value / 16}rem`;
