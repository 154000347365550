import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import icon from "../Content/icon.png";
import rock10 from "../Content/10.png";
import { useRockState } from "../Hooks/useRockState";
import { useWeb3React } from "../Hooks/useWeb3React";
import { DateTime } from "luxon";
import { utils } from "ethers";
import { Link } from "react-router-dom";

const Container = styled.div`
  padding: ${pr(64)};

  max-width: ${pr(960)};
  margin: auto;

  @media (max-width: 959px) {
    max-width: unset;
    padding: ${pr(32)} ${pr(24)};
  }

  p.para {
    font-size: ${pr(18)};

    @media (max-width: 959px) {
      font-size: ${pr(12)};
    }
  }

  .big-rock img {
    margin: auto;
    display: block;
    height: ${pr(400)};
    max-height: 50vh;

    @media (max-width: 959px) {
      height: ${pr(200)};
      max-height: 25vh;
    }
  }

  h1 {
    font-size: ${pr(52)};
    text-align: center;

    @media (max-width: 959px) {
      font-size: ${pr(28)};
    }
  }

  .mint {
    padding-top: ${pr(40)};
    display: flex;

    @media (max-width: 959px) {
      display: block;
    }

    > div:not(.preview) {
      padding-left: ${pr(80)};
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 959px) {
        padding-left: 0;
        padding-top: ${pr(24)};

        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          text-align: center;
        }
      }
    }

    .preview img {
      box-shadow: 0 0 24px -8px rgba(0, 0, 0, 0.85);
      margin: auto;
      display: block;
      height: ${pr(400)};
      max-height: 50vh;
    }
  }

  p.status-pending {
    color: #ffed94;
    font-weight: bold;
  }

  p.status-success {
    color: #00ca22;
    font-weight: bold;
  }

  p.status-fail {
    color: #ff0000;
    font-weight: bold;
  }

  p.mint-start {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.red.main};
    text-decoration: line-through;
  }

  p.sold-out {
    padding-top: ${pr(24)};
    font-weight: bold;
  }

  p {
    font-size: ${pr(16)};
  }

  div.users-rocks {
    padding-top: ${pr(40)};
    display: flex;
    flex-direction: column;

    @media (max-width: 959px) {
      padding-top: 0;
    }
  }

  div.table {
    display: flex;
    margin: ${pr(30)} ${pr(30)} 0;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 959px) {
      justify-content: flex-start;
    }

    div.cell {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: ${pr(16)};

      @media (max-width: 959px) {
        width: 100%;
      }

      p {
        text-align: center;
        font-size: ${pr(18)};
        font-weight: bold;
      }

      .rock-img img {
        width: 100%;
      }
    }
  }

  div.view-all {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    a {
      color: ${({ theme }) => theme.palette.red.main};
    }
  }
`;

const InputButton = styled.button`
  color: black;
  height: ${pr(36)};
  width: ${pr(36)};
  background-color: white;
  font-weight: bold;
  font-size: ${pr(16)};
  cursor: pointer;
`;

const Input = styled.input<{ fullWidth?: boolean }>`
  height: ${pr(30)};
  width: ${({ fullWidth }) => (fullWidth ? undefined : pr(75))};
  flex-grow: ${({ fullWidth }) => (fullWidth ? 1 : 0)};
  font-size: ${pr(18)};
  text-align: center;
  color: black;
`;

const MintButton = styled.button`
  background-color: ${({ theme }) => theme.palette.red.main};
  border: 1px solid white;
  font-size: ${pr(18)};
  margin-top: ${pr(12)};
  padding: ${pr(6)} ${pr(16)} ${pr(8)};

  :active {
    background-color: ${({ theme }) => theme.palette.red.dark};
  }

  :not(:disabled) {
    cursor: pointer;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

enum MintStatus {
  Pending = "pending",
  Success = "success",
  Fail = "fail",
}

export const Home: React.FC = () => {
  const [num, _setNum] = React.useState(1);
  const { state, fetchRocksOf, buyRocks } = useRockState(true);
  const {
    available,
    price,
    total,
    lastError,
    maxBuy,
    startTime,
    tokensOf,
    bal,
  } = state;
  const { account } = useWeb3React();
  const [mintResult, setMintResult] = React.useState("");
  const [mintStatus, setMintStatus] = React.useState(MintStatus.Pending);
  const [minting, setMinting] = React.useState(false);
  const [started, setStarted] = React.useState(true);
  const [user, setUser] = React.useState("");

  const lastRocklist = React.useRef<number[] | undefined>();
  const rockList = React.useMemo(() => {
    if (utils.isAddress(user)) {
      lastRocklist.current = tokensOf[user];
      return lastRocklist.current;
    }
    return lastRocklist.current;
  }, [tokensOf, user]);

  const lastUser = React.useRef("");
  React.useEffect(() => {
    if (lastUser.current !== user && utils.isAddress(user)) {
      lastUser.current = user;
      fetchRocksOf(user);
    }
  }, [fetchRocksOf, user]);

  React.useEffect(() => {
    if (account) {
      setUser(account);
    }
  }, [account]);

  const maxRemaining = React.useMemo(() => {
    return Math.min(available ?? 0, maxBuy ?? 0);
  }, [available, maxBuy]);

  const setNum = React.useCallback(
    (num: number) => {
      _setNum(Math.min(maxRemaining ?? 1, Math.max(1, num)));
    },
    [_setNum, maxRemaining]
  );

  React.useEffect(() => setNum(num), [maxRemaining, num, setNum]);

  const handleDecrement = React.useCallback(
    () => setNum(num - 1),
    [num, setNum]
  );

  const handleIncrement = React.useCallback(
    () => setNum(num + 1),
    [num, setNum]
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNum(Number(e.target.value));
    },
    [setNum]
  );

  const totalMintCost = React.useMemo(() => {
    if (price === undefined) {
      return "-";
    }
    return price * num;
  }, [price, num]);

  const handleMint = React.useCallback(async () => {
    if (!minting) {
      const buyingNum = num;
      setMinting(true);
      setMintResult("Pending...");
      setMintStatus(MintStatus.Pending);
      const result = await buyRocks(buyingNum);
      setMinting(false);
      if (result !== false) {
        setMintResult(
          `Success! Bought ${buyingNum} rock${buyingNum !== 1 ? "s" : ""}.`
        );
        setMintStatus(MintStatus.Success);
        fetchRocksOf(account!);
      } else {
        setMintResult(`Transaction Failed! Please try again.`);
        setMintStatus(MintStatus.Fail);
      }
    }
  }, [account, buyRocks, fetchRocksOf, minting, num]);

  const formatStartDate = React.useMemo(
    () => (startTime ? new Date(startTime * 1000).toLocaleDateString() : "-"),
    [startTime]
  );

  const formatStartTime = React.useMemo(
    () => (startTime ? new Date(startTime * 1000).toLocaleTimeString() : "-"),
    [startTime]
  );

  const checkStarted = React.useCallback(() => {
    setStarted(startTime !== undefined && Date.now() / 1000 >= startTime);
  }, [startTime]);

  React.useEffect(() => {
    checkStarted();
    const interval = window.setInterval(() => {
      checkStarted();
    }, 100);

    return () => window.clearInterval(interval);
  }, [checkStarted]);

  const handleUserChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUser(e.target.value);
    },
    [setUser]
  );

  const insufficientBal = React.useMemo(
    () => utils.parseEther(((price ?? 0) * num).toString()).gt(bal),
    [price, num, bal]
  );

  return (
    <Container>
      <div className="big-rock">
        <img src={icon} alt="" />
      </div>
      <h1>AvaxRock</h1>
      <p className="para">
        Originally on ETH, rocks are now on Avalanche!
        <br />
        <br />
        These rocks are hand crafted, unique, and celebrate the Avalanche
        ecosystem along with the history of Crypto! Each Rock is an ERC721
        ("Non-Fungible Token") secured on the Avalanche Blockchain. You should
        note that these virtual rocks serve NO PURPOSE beyond giving you a
        strong sense of pride in being an owner of 1 of the only 102 rocks in
        the game.
      </p>

      <p className="sold-out">Sold out in 2 minutes!</p>
      <p className="mint-start">
        Minting opens at {formatStartDate} {formatStartTime} (
        {DateTime.local().toFormat("ZZZZ")}).
        <br />
        Rocks will be revealed within 6 hours after sell out or 7 days after
        minting opens.
      </p>
      <div className="mint">
        <div className="preview">
          <img alt="Preview" src={rock10} />
        </div>
        <div>
          <div className="inputs">
            <InputButton onClick={handleDecrement}>-</InputButton>
            <Input
              min={1}
              max={maxRemaining}
              onChange={handleChange}
              value={num}
            />
            <InputButton onClick={handleIncrement}>+</InputButton>
          </div>
          <MintButton
            onClick={handleMint}
            disabled={
              !started || minting || insufficientBal || num < 1 || !account
            }
          >
            {account
              ? `Mint ${num} Rock${
                  num !== 1 ? "s" : ""
                } (${totalMintCost} AVAX)`
              : "Not Connected"}
          </MintButton>
          {insufficientBal && (
            <p className="status-fail">Insufficient Balance</p>
          )}
          <p>
            {available ?? "-"} / {total ?? "-"} Available. Max{" "}
            {maxRemaining ?? "-"} per transaction. Each rock is {price ?? "-"}{" "}
            AVAX.
          </p>
          <p className={`status-${mintStatus}`}>{mintResult}</p>
          {mintStatus === MintStatus.Fail && (
            <p className="status-fail">{lastError}</p>
          )}
          <div className="view-all">
            <Link to="/all">View all rocks</Link>
          </div>
        </div>
      </div>
      <div className="users-rocks">
        <h2>User's Rocks</h2>
        <Input fullWidth value={user} onChange={handleUserChange} />
        {!!rockList || (
          <Loading>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Loading>
        )}
        <div className="table">
          {rockList?.map((rock) => (
            <div className="cell" key={`rock-${rock}`}>
              <p>Unique AvaxRock #{rock}</p>
              <div className="rock-img">
                <img alt="" src={`/img/tokens/${rock}.png`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
