import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { useRockState } from "../Hooks/useRockState";

const Container = styled.div`
  display: flex;
  margin: ${pr(30)} ${pr(30)} 0;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 959px) {
    justify-content: flex-start;
  }

  div.cell {
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: ${pr(16)};

    @media (max-width: 959px) {
      width: 100%;
    }

    p {
      text-align: center;
      font-size: ${pr(18)};
      font-weight: bold;
    }

    .rock-img {
      display: flex;
      justify-content: center;

      img {
        width: 50%;
      }
    }
  }
`;

export const All: React.FC = () => {
  const { state } = useRockState(true);
  const { total } = state;

  return (
    <Container>
      {[...Array(total)].map((_, rock) => (
        <div className="cell" key={`rock-${rock}`}>
          <p>Unique AvaxRock #{rock}</p>
          <div className="rock-img">
            <img alt="" src={`/img/tokens/${rock}.png`} />
          </div>
        </div>
      ))}
    </Container>
  );
};
