import * as React from "react";
import styled from "styled-components";
import { pr } from "../AppTheme";
import { useWeb3React } from "../Hooks/useWeb3React";
import { useInjected } from "../Contexts/InjectedContext";
import { useEagerConnect, useInactiveListener } from "../Hooks";
import { InjectedConnector } from "@web3-react/injected-connector";
import { UnsupportedChainIdError } from "@web3-react/core";
import { useChangeNetwork } from "../Hooks/useChangeNetwork";
import icon from "../Content/icon.png";
import { Link } from "react-router-dom";

const Container = styled.div`
  max-width: ${pr(960)};
  padding: ${pr(24)} 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 959px) {
    max-width: unset;
    padding-left: ${pr(18)};
    padding-right: ${pr(18)};
  }

  div.left {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      max-height: 100%;
      min-height: 0;
      width: auto;
      height: ${pr(64)};
      display: block;
    }

    div.h {
      font-family: "Roboto Slab", serif;
      color: white;
      font-weight: bold;
      font-size: ${pr(18)};
      padding-left: ${pr(24)};
    }
  }
`;

const ConnectButton = styled.button`
  background-color: ${({ theme }) => theme.palette.red.main};
  font-weight: bold;
  font-size: ${pr(18)};
  padding: ${pr(6)} ${pr(32)} ${pr(8)};
  border: 1px solid white;
  cursor: pointer;

  @media (max-width: 959px) {
    font-size: ${pr(12)};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.red.dark};
  }
`;

export const Header: React.FC = () => {
  const { activate, account, connector, error } = useWeb3React();
  const injected = useInjected();
  const changeNetwork = useChangeNetwork();

  const [activatingConnector, setActivatingConnector] = React.useState<
    InjectedConnector | null | undefined
  >();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const handleConnect = React.useCallback(() => {
    if (injected && !account) {
      setActivatingConnector(injected);
      activate(injected);
    }
  }, [activate, injected, account]);

  React.useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      changeNetwork();
    }
  }, [error, changeNetwork]);

  return (
    <Container>
      <div className="left">
        <Link to="/">
          <img alt="" src={icon} />
        </Link>
        <div className="h">AvaxRock</div>
      </div>
      <ConnectButton onClick={handleConnect}>
        {account
          ? `${account.substring(0, 6)}...${account.substring(
              account.length - 4
            )}`
          : "Connect"}
      </ConnectButton>
    </Container>
  );
};
