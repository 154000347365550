import * as React from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutRoute } from "./Components/LayoutRoute";
import { All } from "./Views/All";
import { Home } from "./Views/Home";

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <LayoutRoute exact path="/" component={Home} />
      <LayoutRoute exact path="/all" component={All} />
      <Redirect to="/" />
    </Switch>
  );
};
